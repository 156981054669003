<template>
	<content-page>
		<template v-slot:content>
			<render-content :id="contentId"></render-content>
		</template>
	</content-page>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import ContentPage from "@c/ui/ContentPage";
export default {
	name: "About",
    props: {
        medium: {type: String }
    },
    computed: {
        contentId(){
            let ids = {
                teams: 'helpmsteams',
                zoom: 'helpzoom',
                meet: 'helpgooglemeet',
                skype: 'helpskype'
            }
            return ids[this.medium];
        }
    },
	components: {
		RenderContent,
		ContentPage,
	},
};
</script>
